import { AgeGroup, Center, CenterQuestion, Child, Classroom, CommissionRate, Contract, Coupon, Lead,
  Parent, Program, Provider, ProviderFile, Seat, Tour, TourRequest, WaitlistSpot } from "@legup/legup-model";

import actionTypes from "../infra/constants/actionTypes";

interface FamilyItem {
  child: Child;
  parent: Parent;
  parent2?: Parent;
  center?: Center;
  tour_id?: string;
  request_id?: string;
  tourState?: string;
  spot?: WaitlistSpot;
  seat?: Seat;
}

interface ProviderSeats {
  seats: Seat[];
  classrooms: Classroom[];
  centers: Center[];
  spots: WaitlistSpot[];
  mappings: Array<{ seat_id: string, spot_id: string}>;
  stripe: string | undefined;
  legupFees: boolean | undefined;
  availableSubsidies: Array<{ code: string, name: string}> | undefined;
}

interface UserAccess {
  user_id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  access: string;
}

interface Partner {
  provider: {
    provider_id: string;
    name: string;
  };
  partner: {
    partner_id: string;
    name: string;
  };
  state: string;
  updated: Date;
}

interface IDefaultProviderState {
  query: string | undefined;
  userList: UserAccess[];
  providerList: Provider[];
  familyList: FamilyItem[];
  tourFamilies: Tour[];
  tourRequestFamilies: TourRequest[];
  leadFamilies: Lead[];
  enrolledFamilies: any[],
  questionList: CenterQuestion[];
  centerCount: number | undefined;
  providerSeats: ProviderSeats | undefined;
  currentProvider: Provider | undefined;
  currentProviderFiles: ProviderFile[] | undefined;
  report: any | undefined;
  actionCenterData: any | undefined;
  actionLegupFees: boolean | undefined;
  classroomList: Classroom[] | undefined;
  contractList: Contract[] | undefined;
  programList: Program[] | undefined;
  ageGroupsByContractId: any | undefined;
  ageGroupsList: any | undefined;
  partnerList: Partner[] | undefined;
  couponList: Coupon[] | undefined;
  couponWaiveSubsidies: boolean | undefined;
  couponSubsidies: Array<{code: string, name: string}> | undefined;
  commission: CommissionRate | undefined;
  stripeUrls: Array<{ provider_id: string, url?: string }> | undefined;
  providerFiles: ProviderFile[] | undefined;
  addSuccess: boolean | undefined;
  updateSuccess: boolean | undefined;
  updateOnboardingSuccess: boolean | undefined;
  updateCenterStatesSuccess: boolean | undefined;
  updateNoWaiveSubsidiesSuccess: boolean | undefined;
  signupSuccess: boolean | undefined;
  removeUserSuccess: boolean | undefined;
  readActionItemsSuccess: boolean | undefined;
  loadingFiles: boolean | undefined;
  addFileSuccess: boolean | undefined;
  errorCode: string | undefined;
  isLoadingActionItems: boolean | undefined;
  unreadMessages: Number;
}
const defaultProductState: IDefaultProviderState = {
  query: undefined,
  userList: undefined,
  providerList: undefined,
  familyList: undefined,
  tourFamilies: undefined,
  tourRequestFamilies: undefined,
  leadFamilies: undefined,
  enrolledFamilies: undefined,
  questionList: undefined,
  centerCount: undefined,
  providerSeats: undefined,
  report: undefined,
  actionCenterData: undefined,
  actionLegupFees: undefined,
  currentProvider: null,
  currentProviderFiles: null,
  classroomList: undefined,
  contractList: undefined,
  programList: undefined,
  ageGroupsByContractId: undefined,
  ageGroupsList: undefined,
  partnerList: undefined,
  couponList: undefined,
  couponWaiveSubsidies: undefined,
  couponSubsidies: undefined,
  commission: undefined,
  stripeUrls: undefined,
  providerFiles: undefined,
  addSuccess: undefined,
  updateSuccess: undefined,
  updateOnboardingSuccess: undefined,
  updateCenterStatesSuccess: undefined,
  updateNoWaiveSubsidiesSuccess: undefined,
  signupSuccess: undefined,
  removeUserSuccess: undefined,
  readActionItemsSuccess: undefined,
  loadingFiles: undefined,
  addFileSuccess: undefined,
  errorCode: undefined,
  isLoadingActionItems: undefined,
  unreadMessages: 0,
};

function reduceUnreadBadgeCallback(state: any, action: any) {
  return { ...state, unreadMessages: action.badgeCount };
}

function reduceFindProviders(state: any, action: any) {
  return { ...state, query: action.name };
}

function reduceFindProvidersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const providerList = [];

  if (action.providerList) {
    action.providerList.forEach(p => {
      const provider = new Provider();
      provider.buildFromJSON(p);
      providerList.push(provider);
    });
  }

  return { ...state, providerList, currentProvider: null };
}

function reduceReadProviderCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const provider: Provider = new Provider();
  provider.buildFromJSON(action.provider);

  const files: ProviderFile[] = [];
  action.files.forEach(f => {
    const file = new ProviderFile();
    file.buildFromJSON(f);
    files.push(file);
  });

  return { ...state, currentProvider: provider, currentProviderFiles: files };
}

function reduceReadProviderFamiliesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const familyList: FamilyItem[] = [];
  (action.families || []).forEach(f => {
    let child: Child | undefined;
    let parent: Parent | undefined;
    let parent2: Parent | undefined;
    let center: Center | undefined;
    let spot: WaitlistSpot | undefined;
    let seat: Seat | undefined;

    if (f.child) {
      child = new Child();
      child.buildFromJSON(f.child);
    }
    if (f.parent) {
      parent = new Parent();
      parent.buildFromJSON(f.parent);
    }
    if (f.parent2) {
      parent2 = new Parent();
      parent2.buildFromJSON(f.parent2);
    }
    if (f.center) {
      center = new Center();
      center.buildFromJSON(f.center);
    }
    if (f.spot) {
      spot = new WaitlistSpot();
      spot.buildFromJSON(f.spot);
      spot.setChild(child);
    }
    if (f.seat) {
      seat = new Seat();
      seat.buildFromJSON(f.seat);
    }

    familyList.push({
      child,
      parent,
      parent2,
      center,
      tour_id: f.tour_id,
      request_id: f.request_id,
      tourState: f.tourState,
      spot,
      seat,
    });
  });

  const tourFamilies: Tour[] = [];
  (action.tours || []).forEach(t => {
    const tour: Tour = new Tour();
    tour.buildFromJSON(t);
    tourFamilies.push(tour);
  });

  const tourRequestFamilies: TourRequest[] = [];
  (action.tourRequests || []).forEach(r => {
    const tourRequest: TourRequest = new TourRequest();
    tourRequest.buildFromJSON(r);
    tourRequestFamilies.push(tourRequest);
  });

  const leadFamilies: any[] = [];
  (action.leads || []).forEach(l => {
    const lead: Lead = new Lead();
    lead.buildFromJSON(l);
    leadFamilies.push(lead);
  });

  const enrolledFamilies: any[] = [];
  (action.enrollments || []).forEach(enrollment => {
    let child: Child | undefined;
    let parent: Parent | undefined;
    let center: Center | undefined;
    let today = (new Date()).toISOString();

    if (enrollment.child) {
      child = new Child();
      child.buildFromJSON(enrollment.child);
    }
    if (enrollment.parent) {
      parent = new Parent();
      parent.buildFromJSON(enrollment.parent);
    }
    if (enrollment.center) {
      center = new Center();
      center.buildFromJSON(enrollment.center);
    }

    enrolledFamilies.push({
      parent,
      child,
      center,
      id: enrollment.enrollment_id,
      unenrolled: enrollment.removed_at ? enrollment.removed_at <= today : false,
      pending_unenroll: enrollment.removed_at ? enrollment.removed_at > today : false,
      canReEnroll: !enrollment.re_enrollment_id && enrollment.status === "enrolled",
      status: enrollment.status,
    });
  });

  return {
    ...state,
    familyList,
    tourFamilies,
    tourRequestFamilies,
    leadFamilies,
    enrolledFamilies,
  };
}

function reduceReadProviderClassroomsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const classroomList: Classroom[] = [];
  action.classrooms.forEach(c => {
    const classroom = new Classroom();
    classroom.buildFromJSON(c);
    classroomList.push(classroom);
  });

  return { ...state, classroomList };
}

function reduceReadProviderCommissionCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const commission: CommissionRate = action.commission;

  // Note this is the deposit commission, the programs reducer does the same.
  return { ...state, commission };
}

function reduceReadProviderAgeGroupsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const ageGroupList: AgeGroup[] = [];
  action.ageGroups?.forEach(ag => {
    const ageGroup = new AgeGroup();
    ageGroup.buildFromJSON(ag);
    ageGroupList.push(ageGroup);
  });

  return { ...state, ageGroupList };
}

function reduceReadProviderProgramsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const programList: Program[] = [];
  action.programs.forEach(p => {
    const program = new Program();
    program.buildFromJSON(p);
    programList.push(program);
  });

  const contractList: Contract[] = [];
  action.contracts.forEach(c => {
    const contract = new Contract();
    contract.buildFromJSON(c);
    contractList.push(contract);
  });

  return {
    ...state,
    programList,
    contractList,
    commission: action.commission,
    ageGroupsByContractId: action.ageGroupsByContractId,
  };
}

function reduceReadProviderSeatsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const providerSeats: ProviderSeats = {
    seats: [],
    classrooms: [],
    centers: [],
    spots: [],
    mappings: [],
    stripe: action.stripe,
    legupFees: action.legupFees,
    availableSubsidies: [],
  };

  action.seats.forEach(s => {
    const seat = new Seat();
    seat.buildFromJSON(s);
    providerSeats.seats.push(seat);
  });
  action.classrooms.forEach(c => {
    const classroom = new Classroom();
    classroom.buildFromJSON(c);
    providerSeats.classrooms.push(classroom);
  });
  action.centers.forEach(c => {
    const center = new Center();
    center.buildFromJSON(c);
    providerSeats.centers.push(center);
  });
  action.spots.forEach(s => {
    const spot = new WaitlistSpot();
    spot.buildFromJSON(s);
    providerSeats.spots.push(spot);
  });
  providerSeats.mappings = [].concat(action.mappings || []);
  providerSeats.availableSubsidies = [].concat(action.availableSubsidies || []);

  return { ...state, providerSeats };
}

function reduceReadProviderReportCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, report: action.report };
}

function reduceListQuestions(state: any, action: any) {
  return { ...state, questionList: undefined };
}

function reduceListQuestionsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }
  const questionList = [];
  if (action.questions) {
    action.questions.forEach(q => {
      const question = new CenterQuestion();
      question.buildFromJSON(q);
      questionList.push(question);
    });
  }

  return { ...state, questionList, centerCount: action.centerCount };
}

function reduceReadProviderUserAccessCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, userList: action.users };
}

function reduceListPartnersCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const partnerList: Partner[] = [];
  (action.partners || []).forEach(p => {
    partnerList.push({
      provider: {
        provider_id: p.provider.provider_id.toString(),
        name: p.provider.name,
      },
      partner: {
        partner_id: p.partner.partner_id.toString(),
        name: p.partner.name,
      },
      state: p.state,
      updated: new Date(p.updated),
    });
  });

  return { ...state, partnerList };
}

function reduceListCouponsCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  const couponList: Coupon[] = [];
  (action.coupons || []).forEach(c => {
    const coupon = new Coupon();
    coupon.buildFromJSON(c);
    couponList.push(coupon);
  });
  const couponWaiveSubsidies = action.waive_subsidies;
  const couponSubsidies = action.subsidies || [];

  return { ...state, couponList, couponSubsidies, couponWaiveSubsidies };
}

function reduceNoWaiveSubsidies(state: any, action: any) {
  return { ...state, updateNoWaiveSubsidiesSuccess: undefined };
}

function reduceNoWaiveSubsidiesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, updateNoWaiveSubsidiesSuccess: action.success };
}

function reduceAddProvider(state: any, action: any) {
  return { ...state, addSuccess: undefined };
}

function reduceAddProviderCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, addSuccess: false, errorCode: action.errorCode };
  }

  if (action.provider_id) {
    // Set the ID of the current provider
    const provider: Provider = state.currentProvider ? state.currentProvider : new Provider();

    provider.setId(action.provider_id);

    return { ...state, currentProvider: provider, addSuccess: true };
  }

  return { ...state, addSuccess: false };

}

function reduceUpdateProvider(state: any, action: any) {
  return { ...state, updateSuccess: undefined };
}

function reduceUpdateProviderCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateSuccess: action.success };
}

function reduceUpdateOnboarding(state: any, action: any) {
  return { ...state, updateOnboardingSuccess: undefined };
}

function reduceUpdateOnboardingCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateOnboardingSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateOnboardingSuccess: action.success };
}

function reduceUpdateCenterStatesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, updateCenterStatesSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, updateCenterStatesSuccess: action.success };
}

function reduceSignupUserCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, signupSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, signupSuccess: action.success };
}

function reduceRemoveUserCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, removeUserSuccess: false, errorCode: action.errorCode };
  }

  return { ...state, removeUserSuccess: action.success };
}

function reduceGetStripeUrlCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, errorCode: action.errorCode };
  }

  return { ...state, stripeUrls: action.urls };
}

function reduceReadActionItems(state: any, _action: any) {
  return { ...state, isLoadingActionItems: true };
}

function reduceReadActionItemsCallback(state: any, action: any) {
  if (action.errorCode) {
    return {
      ...state,
      readActionItemsSuccess: false,
      errorCode: action.errorCode,
      isLoadingActionItems: false,
    };
  }

  const actionCenterData = {};
  const actionLegupFees = action.data.legupFees;

  Object.keys(action.data).forEach(col => {
    if (Array.isArray(action.data[col])) {
      actionCenterData[col] = [];
      action.data[col].forEach(d => {
        if (d.seat) {
          const seat = new Seat();
          seat.buildFromJSON(d.seat);
          d.seat = seat;
        }
        if (d.center) {
          const center = new Center();
          center.buildFromJSON(d.center);
          d.center = center;
        }
        if (d.tour) {
          const tour = new Tour();
          tour.buildFromJSON(d.tour);
          d.tour = tour;
        }
        if (d.spot) {
          const spot = new WaitlistSpot();
          spot.buildFromJSON(d.spot);
          d.spot = spot;
        }
        actionCenterData[col].push(d);
      });
    }
  });

  return {
    ...state,
    readActionItemsSuccess: action.success,
    errorCode: action.errorCode,
    isLoadingActionItems: false,
    actionLegupFees,
    actionCenterData,
  };
}

function reduceReadProviderFiles(state: any, _action: any) {
  return { ...state, loadingFiles: true };
}

function reduceReadProviderFilesCallback(state: any, action: any) {
  if (action.errorCode) {
    return { ...state, loadingFiles: false, errorCode: action.errorCode };
  }

  const files: ProviderFile[] = [];
  action.files.forEach(f => {
    const file: ProviderFile = new ProviderFile();
    file.buildFromJSON(f);
    files.push(file);
  });

  return { ...state, loadingFiles: false, errorCode: action.errorCode, providerFiles: files };
}

function reduceAddProviderFileCallback(state: any, action: any) {
  return { ...state, addFileSuccess: !action.errorCode, errorCode: action.errorCode };
}

function reduceAddProviderFile(state: any, _action: any) {
  return { ...state, addFileSuccess: undefined };
}

function reduceUpdateActionItems(state: any, action: any) {
  return { ...state, actionCenterData: action.data };
}

function reduceIncrementUnreadMessages(state: any, action: any) {
  return { ...state, unreadMessages: state.unreadMessages + 1 };
}

function reduceConfirmFeesStructure(state: any, action: any) {
  return { ...state };
}

function reduceConfirmFeesStructureCallback(state: any, action: any) {
  return { ...state, success: action.data };
}

function reduceProviders(state = defaultProductState, action: any) {
  switch (action.type) {
    case actionTypes.PROVIDER_UNREAD_CALLBACK:
      return reduceUnreadBadgeCallback(state, action);
    case actionTypes.PROVIDER_FIND:
      return reduceFindProviders(state, action);
    case actionTypes.PROVIDER_FIND_CALLBACK:
      return reduceFindProvidersCallback(state, action);
    case actionTypes.PROVIDER_READ_CALLBACK:
      return reduceReadProviderCallback(state, action);
    case actionTypes.PROVIDER_READ_FAMILIES_CALLBACK:
      return reduceReadProviderFamiliesCallback(state, action);
    case actionTypes.PROVIDER_READ_CLASSROOMS_CALLBACK:
      return reduceReadProviderClassroomsCallback(state, action);
    case actionTypes.PROVIDER_READ_COMMISSION_CALLBACK:
      return reduceReadProviderCommissionCallback(state, action);
    case actionTypes.PROVIDER_READ_AGEGROUPS_CALLBACK:
      return reduceReadProviderAgeGroupsCallback(state, action);
    case actionTypes.PROVIDER_READ_PROGRAMS_CALLBACK:
      return reduceReadProviderProgramsCallback(state, action);
    case actionTypes.PROVIDER_READ_SEATS_CALLBACK:
      return reduceReadProviderSeatsCallback(state, action);
    case actionTypes.PROVIDER_READ_REPORT_CALLBACK:
      return reduceReadProviderReportCallback(state, action);
    case actionTypes.PROVIDER_QUESTIONS:
      return reduceListQuestions(state, action);
    case actionTypes.PROVIDER_QUESTIONS_CALLBACK:
      return reduceListQuestionsCallback(state, action);
    case actionTypes.PROVIDER_READ_USER_ACCESS_CALLBACK:
      return reduceReadProviderUserAccessCallback(state, action);
    case actionTypes.PROVIDER_LIST_PARTNERS_CALLBACK:
      return reduceListPartnersCallback(state, action);
    case actionTypes.PROVIDER_LIST_COUPONS_CALLBACK:
      return reduceListCouponsCallback(state, action);
    case actionTypes.PROVIDER_ADD:
      return reduceAddProvider(state, action);
    case actionTypes.PROVIDER_ADD_CALLBACK:
      return reduceAddProviderCallback(state, action);
    case actionTypes.PROVIDER_UPDATE:
      return reduceUpdateProvider(state, action);
    case actionTypes.PROVIDER_UPDATE_CALLBACK:
      return reduceUpdateProviderCallback(state, action);
    case actionTypes.PROVIDER_NO_WAIVE_SUBSIDIES:
      return reduceNoWaiveSubsidies(state, action);
    case actionTypes.PROVIDER_NO_WAIVE_SUBSIDIES_CALLBACK:
      return reduceNoWaiveSubsidiesCallback(state, action);
    case actionTypes.PROVIDER_UPDATE_ONBOARDING:
      return reduceUpdateOnboarding(state, action);
    case actionTypes.PROVIDER_UPDATE_ONBOARDING_CALLBACK:
      return reduceUpdateOnboardingCallback(state, action);
    case actionTypes.PROVIDER_UPDATE_CENTER_STATES_CALLBACK:
      return reduceUpdateCenterStatesCallback(state, action);
    case actionTypes.PROVIDER_SIGNUP_USER_CALLBACK:
      return reduceSignupUserCallback(state, action);
    case actionTypes.PROVIDER_REMOVE_USER_CALLBACK:
      return reduceRemoveUserCallback(state, action);
    case actionTypes.PROVIDER_STRIPE_AUTHORIZE_CALLBACK:
      return reduceGetStripeUrlCallback(state, action);
    case actionTypes.PROVIDER_READ_ACTION_ITEMS:
      return reduceReadActionItems(state, action);
    case actionTypes.PROVIDER_READ_ACTION_ITEMS_CALLBACK:
      return reduceReadActionItemsCallback(state, action);
    case actionTypes.PROVIDER_UPDATE_ACTION_ITEMS:
      return reduceUpdateActionItems(state, action);
    case actionTypes.PROVIDER_READ_FILES_CALLBACK:
      return reduceReadProviderFilesCallback(state, action);
    case actionTypes.PROVIDER_READ_FILES:
      return reduceReadProviderFiles(state, action);
    case actionTypes.PROVIDER_ADD_FILE_CALLBACK:
      return reduceAddProviderFileCallback(state, action);
    case actionTypes.PROVIDER_ADD_FILE:
      return reduceAddProviderFile(state, action);
    case actionTypes.PROVIDER_INCREMENT_UNREAD_MESSAGES:
      return reduceIncrementUnreadMessages(state, action);
    case actionTypes.PROVIDER_CONFIRM_FEES_STRUCTURE:
      return reduceConfirmFeesStructure(state, action);
    case actionTypes.PROVIDER_CONFIRM_FEES_STRUCTURE_CALLBACK:
      return reduceConfirmFeesStructureCallback(state, action);
    default:
      return state;
  }
}

export default reduceProviders;
